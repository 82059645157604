<template>
    <div id="deliveryDialog">
        <div class="delivery-dailog">
            <div class="delivery-bg" @click="deliCancel"></div>
            <div class="delivery-box" >
                <div class="delivery-head">
                    <span>{{shopType == 1 ? '发货':'配送'}}</span>
                    <img src="@/assets/icon/close.png" alt="" @click.stop="deliCancel" />
                </div>
                <div class="delivery-inner">
                    <div class="deli-inner-head">
                        <div class="deli-inner-head-li l1">快递</div>
                        <div class="deli-inner-head-li l2">订单号</div>
                        <div class="deli-inner-head-li l3">收货信息</div>
                        <div class="deli-inner-head-li l4">发货单号</div>
                    </div>
                    <div class="deli-inner-mess">
                        <div class="deli-inner-mess-li l1">{{ShipperName}}</div>
                        <div class="deli-inner-mess-li l2">{{orderNo}}</div>
                        <div class="deli-inner-mess-li l3">{{takeDeliveryPerson}}({{telephone}})</div>
                        <div class="deli-inner-mess-li l4">
                            <el-input v-model="deliveryNo" placeholder="请输入发货单号"></el-input>
                            <el-button @click="checkDeliveryNo" :disabled='ifDisabled' :class="{'ifTimeout':ifDisabled}">{{ifDisabled == false ? '检出': (num +'s')}}</el-button>
                        </div>
                    </div>
                    <div class="deli-inner-remark">
                        <el-input v-model="remark" type="textarea" placeholder="备注信息" resize="none"></el-input>
                    </div>
                    <div class="deli-confirm">
                        <el-button @click="deliConfirm">保存</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {logisticsName} from '@/api/order/orderAPI'
export default {
    props:{
        orderNo:{
            type:String,
            default(){
                return ''
            }
        },
        takeDeliveryPerson:{
            type:String,
            default(){
                return ''
            }
        },
        telephone:{
            type:String,
            default(){
                return ''
            }
        },
    },
    data(){
        return{
            shopType:this.$store.getters.shopType,
            deliveryNo:'',
            senddeliveryNo:'',
            ShipperCode:'',
            ShipperName:'',
            remark:'',
            timer:null,
            num:10,
            ifDisabled: false           // 紧张按钮点击
        }
    },
    methods: {
        deliCancel() {
            this.$emit("deliCancel");
        },
        deliConfirm() {
            if(this.ShipperCode == ''){
                this.$message({
                    type:'warning',
                    message:'请输入发货单号'
                })
                return
            }else{
                this.$emit("deliConfirm",{
                    
                    senddeliveryNo:this.senddeliveryNo,
                    ShipperCode:this.ShipperCode,
                    ShipperName:this.ShipperName,
                    remark:this.remark
                });
            }
        },
        reset(){
            this.deliveryNo = ''
            this.ShipperName = ''
            this.orderNo = ''
            this.remark = ''
            this.takeDeliveryPerson = ''
            this.telephone = ''
        },
        vertify(){
            if(this.deliveryNo == '') {
                this.$message({
                    type:'warning',
                    message:'请输入发货单号'
                })
                return false
            }
            return true
        },
        async checkDeliveryNo(){
            if(this.vertify()){
                let that = this
                that.ifDisabled = true
                that.timer = setInterval(()=>{
                    that.num --
                    if(that.num == 0){
                        clearInterval(this.timer)
                        that.ifDisabled = false
                        that.num = 10
                    }
                },1000)
                const res = await logisticsName(this.deliveryNo)
                const {flag,data,msg} = res.data
                if(flag){
                    this.ShipperCode = data.ShipperCode
                    this.ShipperName = data.ShipperName
                    this.senddeliveryNo = this.deliveryNo
                    // clearInterval(this.timer)
                    // this.ifDisabled = false
                    // this.num = 10
                }else{
                    this.$message({
                    type:'error',
                    message:msg
                })
                }  
            }
        }
    }
}
</script>

<style lang="less" scoped>
.delivery-dailog {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .delivery-bg {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 0;
    top: 0;
  }
}
.delivery-box {
    .box(58%,auto,#fff,0.4rem);
    position: relative;
    z-index: 9999999;
    border: 1px solid #e4e7ed;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
    padding:0 3.5rem;
    .delivery-head {
        height: 4.7rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e0e1e2;
        span {
            line-height: 4.7rem;
            font-size: 2rem;
            color: #333333;
            font-weight: bold;
            .flexSt();
            &::before{
                display: inline-block;
                content: '';
                width: 0.5rem;
                height: 1.9rem;
                // background: #1955FA;
                border-radius: 0.3rem;
                margin-right: 0.9rem;
            }
        }
        img {
            display: block;
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }
    .delivery-inner{
        padding: 3.9rem 0 0 0;
        .deli-inner-head{
            .flexSt();
            .word(1.4rem,#000,2rem,normal,left);
            margin-bottom: 1.6rem;
        }
        .deli-inner-head-li,.deli-inner-mess-li{
            flex: 1;
        }
        .l4{
            &.deli-inner-mess-li{
                padding: 0 !important;
            }
            .flexSt();
            /deep/ .el-button{
                .box(5.2rem,4rem,#F5F7FA);
                padding: 0;
                .word(1.4rem,#000,4rem,normal,center);
                border-color: #DCDFE6;
                border-top-right-radius: 0.4rem;
                border-bottom-right-radius: 0.4rem;
                background: #F5F7FA;
                margin-left: -0.2rem;
                position: relative;
                z-index: 10;
                &.ifTimeout{
                    color: #1955FA !important;
                }
            }
            /deep/ .el-input{
                width: auto;
                flex: 1;
                input{
                    .word(1.4rem,#000,4rem,normal);
                    text-indent: 1.5rem;
                    border-top-left-radius: 0.4rem;
                    border-bottom-left-radius: 0.4rem;
                    border-right: 0;
                    border-color: #DCDFE6;
                    padding-left: 0;
                }
                ::placeholder{
                    .word(1.4rem,#000,4rem,normal);
                }
            }
        }
        .deli-inner-mess{
            .flexSt();
            .deli-inner-mess-li{
                padding: 1rem 0;
                .word(1.4rem,#000,2rem,normal,left);
            }
        }
        .deli-inner-remark{
            margin:1.6rem 0 0 0 ;
            /deep/ .el-textarea{
                margin: 0;
                padding: 0;
                textarea{
                    border:0.7rem solid #F5F7FA;
                    height: 12.7rem;
                    .word(1.4rem,#000,2rem,normal);
                    border-radius: 1rem;
                    margin: 0;
                    padding: 0.9rem 1.2rem;
                }
                ::placeholder{
                    .word(1.4rem,#000,2rem,normal);
                }
            }
        }
        .deli-confirm{
            padding: 3.8rem 0 3.2rem 0;
            .el-button{
                padding: 0;
                .box(36rem,4.3rem,linear-gradient(270deg, #4A8CFF 0%, #4A8CFF 100%),0.4rem);
                .word(1.8rem,#fff,4.3rem,normal,center);
            }
        }
    }
    
}
</style>