// 解决弹出层禁止底层页面滚动无效的问题
let _scrollTop;
class ModalHelper {
  // popup 显示时调用
  static afterOpen() {
    _scrollTop = document.scrollingElement.scrollTop;
    document.body.style.overflow = "hidden";
    // document.body.style.top = -_scrollTop + 'px'
  }

  // popup 关闭时调用
  static beforeClose() {
    document.body.style.overflow = "";
    // document.body.style.top = ''
    // 使 scrollTop 恢复原状
    document.scrollingElement.scrollTop = _scrollTop;
  }
}

export default ModalHelper;
